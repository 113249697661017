import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { CustomChart1Component } from './custom-chart1/custom-chart1.component';
import { FlexLayoutModule } from '@angular/flex-layout';



@NgModule({
  declarations: [CustomChart1Component],
  imports: [
    CommonModule,
    NgxChartsModule,
    MatIconModule,
    MatButtonModule,
    FlexLayoutModule
  ],
  exports: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ComponentsModule { }
