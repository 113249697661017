import { Subscription } from 'rxjs';
// Angular
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
// Layout
import {
  LayoutConfigService,
  SplashScreenService,
  TranslationService,
} from './core/_base/layout';
// language list
import { locale as enLang } from './core/_config/i18n/en';
import { locale as chLang } from './core/_config/i18n/ch';
import { locale as esLang } from './core/_config/i18n/es';
import { locale as jpLang } from './core/_config/i18n/jp';
import { locale as deLang } from './core/_config/i18n/de';
import { locale as frLang } from './core/_config/i18n/fr';
import { AngularFireAuth } from '@angular/fire/auth';
import { AppState } from './core/reducers';
import { Store, select } from '@ngrx/store';
import { UserRequested } from './core/auth';
import {
  SetLoggedIn,
  CleanAuth,
  FetchGroups,
  FetchPlans,
} from './core/auth/_actions/auth.actions';
import { currentUser } from './core/charts/_selectors/charts.selectors';
import { SetChartType } from './core/charts/_actions/charts.actions';

import * as firebase from 'firebase/app';
import { GoogleAnalyticsService } from 'ngx-google-analytics';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'body[kt-root]',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {
  // Public properties
  title = 'Metronic';
  loader: boolean;
  private unsubscribe: Subscription[] = []; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/

  /**
   * Component constructor
   *
   * @param translationService: TranslationService
   * @param router: Router
   * @param layoutConfigService: LayoutConfigService
   * @param splashScreenService: SplashScreenService
   */
  constructor(
    private translationService: TranslationService,
    private afa: AngularFireAuth,
    private store: Store<AppState>,
    private router: Router,
    private layoutConfigService: LayoutConfigService,
    private splashScreenService: SplashScreenService,
    private $gaService: GoogleAnalyticsService
  ) {
    // register translations
    this.translationService.loadTranslations(
      enLang,
      chLang,
      esLang,
      jpLang,
      deLang,
      frLang
    );
  }

  /**
   * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
   */

  /**
   * On init
   */
  ngOnInit(): void {
    this.$gaService.event('web_app_started');
    // enable/disable loader
    this.afa.authState.subscribe((user) => {
      if (user) {
        this.store.dispatch(
          new SetLoggedIn({ createdAt: user.metadata.creationTime })
        );
        this.router.navigateByUrl('/');
        this.store.dispatch(
          new SetLoggedIn({ createdAt: user.metadata.creationTime })
        );
        this.store.dispatch(new UserRequested());
        // this.store.dispatch(new FetchGroups({ uid: user.uid }))
      } else {
        this.store.dispatch(new CleanAuth());
        this.router.navigateByUrl('/auth/login');
      }
    });
    this.store.pipe(select(currentUser)).subscribe((value) => {
      if (value) {
        // id: value.id
        this.store.dispatch(new FetchGroups({ id: value.id }));
        this.store.dispatch(new FetchPlans({ uid: value.id }));
      }
    });
    this.loader = this.layoutConfigService.getConfig('page-loader.type');

    const routerSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // hide splash screen
        this.splashScreenService.hide();

        // scroll to top on every route change
        window.scrollTo(0, 0);

        // to display back the body content
        setTimeout(() => {
          document.body.classList.add('page-loaded');
        }, 1000);
      }
    });
    this.unsubscribe.push(routerSubscription);
  }

  /**
   * On Destroy
   */
  ngOnDestroy() {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }

  ngAfterViewInit(): void {
    firebase.analytics().logEvent('web-app-started');
  }
}
