// Angular
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
// Components
import { BaseComponent } from './views/theme/base/base.component';
// Auth
import { AuthGuard } from './core/auth';
import { NgxPermissionsModule } from 'ngx-permissions';

const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () =>
      import('./views/pages/auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'error',
    loadChildren: () =>
      import('./views/pages/error/error.module').then((m) => m.ErrorModule),
  },
  {
    path: '',
    component: BaseComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'profile',
        loadChildren: () =>
          import('./views/pages/profile/profile.module').then(
            (m) => m.ProfileModule
          ),
      },
      {
        path: 'my-archive',
        loadChildren: () =>
          import('./views/pages/archive/archive.module').then(
            (m) => m.ArchiveModule
          ),
      },
      {
        path: 'other-user',
        loadChildren: () =>
          import('./views/pages/others-archive/others-archive.module').then(
            (m) => m.OthersArchiveModule
          ),
      },
      {
        path: 'live-view',
        loadChildren: () =>
          import('./views/pages/live-view/live-view.module').then(
            (m) => m.LiveViewModule
          ),
      },
      // {
      //   path: 'pulse-bluetooth',
      //   loadChildren: () =>
      //     import('./views/pages/pulse-bluetooth/pulse-bluetooth.module').then(
      //       (m) => m.PulseBluetoothModule
      //     ),
      // },
      {
        path: 'custom-charts',
        loadChildren: () =>
          import('./views/pages/custom-charts/custom-charts.module').then(
            (m) => m.CustomChartsModule
          ),
      },
      // {
      //   path: 'mail',
      //   loadChildren: () => import('./views/pages/apps/mail/mail.module').then(m => m.MailModule),
      // },
      // {
      //   path: 'ecommerce',
      //   loadChildren: () => import('./views/pages/apps/e-commerce/e-commerce.module').then(m => m.ECommerceModule),
      // },
      // {
      //   path: 'ngbootstrap',
      //   loadChildren: () => import('./views/pages/ngbootstrap/ngbootstrap.module').then(m => m.NgbootstrapModule),
      // },
      // {
      //   path: 'material',
      //   loadChildren: () => import('./views/pages/material/material.module').then(m => m.MaterialModule),
      // },
      // {
      //   path: 'wizard',
      //   loadChildren: () => import('./views/pages/wizard/wizard.module').then(m => m.WizardModule),
      // },
      // {
      //   path: 'builder',
      //   loadChildren: () => import('./views/theme/content/builder/builder.module').then(m => m.BuilderModule),
      // },
      { path: '', redirectTo: 'profile', pathMatch: 'full' },
      { path: '**', redirectTo: 'dashboard', pathMatch: 'full' },
    ],
  },
  { path: '**', redirectTo: 'error/403', pathMatch: 'full' },
];

@NgModule({
  imports: [CommonModule, RouterModule.forRoot(routes), NgxPermissionsModule.forChild()],
  exports: [RouterModule],
})
export class AppRoutingModule {}
