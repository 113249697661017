// Angular
import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
// RxJS
import { Subscription } from 'rxjs';
// Layout
import { SubheaderService } from '../../../../../core/_base/layout';
import { Breadcrumb } from '../../../../../core/_base/layout/services/subheader.service';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/core/reducers';
import { SetChartType } from 'src/app/core/charts/_actions/charts.actions';
import { Router } from '@angular/router';
import { LoadRecordings, LoadSharedRecordings } from 'src/app/core/archive/_actions/archive.actions';
import { LoadOthersRecordings } from 'src/app/core/others-archive/_actions/others-archive.actions';
import {ChoosenSensorService} from '../../../../../services/choosen-sensor/choosen-sensor.service';

@Component({
  selector: 'kt-subheader1',
  templateUrl: './subheader1.component.html',
  styleUrls: ['./subheader1.component.scss'],
  encapsulation : ViewEncapsulation.None,
})
export class Subheader1Component implements OnInit, OnDestroy, AfterViewInit {
  // Public properties
  @Input() fixed = true;
  @Input() clear = false;
  @Input() width = 'fluid';
  @Input() subheaderClasses = '';
  @Input() subheaderContainerClasses = '';
  @Input() displayDesc = false;
  @Input() displayDaterangepicker = true;

  today: number = Date.now();
  title = '';
  desc = '';
  breadcrumbs: Breadcrumb[] = [];
  option = 'Skin Response';

  // Private properties
  private subscriptions: Subscription[] = [];

  /**
   * Component constructor
   *
   * @param subheaderService: SubheaderService
   */
  constructor(public subheaderService: SubheaderService,
              private store: Store<AppState>,
              public router: Router,
              public sensor: ChoosenSensorService) {
  }

  /**
   * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
   */

  /**
   * On init
   */
  ngOnInit() {
    // this.store.dispatch(new SetChartType({ chartType: 'Skin Response' }));
  }

  /**
   * After view init
   */
  ngAfterViewInit(): void {
    this.subscriptions.push(this.subheaderService.title$.subscribe(bt => {
      // breadcrumbs title sometimes can be undefined
      if (bt) {
        Promise.resolve(null).then(() => {
          this.title = bt.title;
          this.desc = bt.desc;
        });
      }
    }));

    this.subscriptions.push(this.subheaderService.breadcrumbs$.subscribe(bc => {
      Promise.resolve(null).then(() => {
        this.breadcrumbs = bc;
      });
    }));
  }

  chartTypeChange(event: any) {
    this.sensor.choosenSensor.next(event.value);
    this.option = event.value;
    this.store.dispatch(new SetChartType({ chartType: event.value }));
    // this.store.dispatch(new LoadRecordings({ chartType: event.value }));
    // this.store.dispatch(new LoadOthersRecordings());
    // this.store.dispatch(new LoadSharedRecordings({ chartType: event.value }));
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    this.subscriptions.forEach(sb => sb.unsubscribe());
  }
}
