<div class="d-flex flex-column flex-root" id="kt_login_wrapper">
	<div class="login login-3 login-signin-on d-flex flex-column flex-lg-row flex-row-fluid bg-white" id="kt_login">
		<!--begin::Aside-->
		<div class="login-aside d-flex flex-row-auto bgi-size-cover bgi-no-repeat p-10 p-lg-10"
			style="background-image: url(./assets/media//bg/bg-12.png);">
			<!--begin: Aside Container-->
			<div class="d-flex flex-row-fluid flex-column justify-content-between">
        <div class="app-version">
          <p>Version {{arrVersion}}</p>
        </div>
				<!--begin: Aside header-->
				<a href="#" class="flex-column-auto mt-5">
					<img src="./assets/media/logos/logo_mindfield_mitDomain.png" class="max-h-70px" style="height: 70px;"/>
				</a>
				<!--end: Aside header-->

				<!--begin: Aside content-->
				<div class="flex-column-fluid d-flex flex-column justify-content-center">
					<h3 class="font-size-h1 mb-5 welcome-text">Welcome to eSense Web App</h3>
					<!-- <p class="font-weight-lighter text-white opacity-80">
						The ultimate Bootstrap, Angular 8, React & VueJS admin theme framework for next generation web
						apps.
					</p> -->
					<div>
						<div class="row login-aside-row">
							<div class="col p-5 text-right">
								<img src="./assets/media/logos/eSense-SkinReponse.png" class="max-h-70px" />
							</div>
							<div class="col p-5">
								<img src="./assets/media/logos/eSense-Temperature.png" class="max-h-70px" />

							</div>
						</div>
						<div class="row login-aside-row">
							<div class="col p-5 text-right">
								<img src="./assets/media/logos/eSense-Pulse.png" class="max-h-70px" />
							</div>
							<div class="col p-5">
								<img src="./assets/media/logos/eSense-Respiration.png" class="max-h-70px" />
							</div>

						</div>
					</div>
				</div>
        <div class="notice">
          <h5>The eSense Web & Mobile App is proudly programmed in Ukraine</h5>
          <img src="./assets/images/stand-with-ukraine.png" alt="flag">
          <h5>#StandWithUkraine</h5>
        </div>
				<!--end: Aside content-->

				<!--begin: Aside footer for desktop-->
				<div class="d-none flex-column-auto d-lg-flex justify-content-between mt-10">
					<div class="opacity-70 font-weight-bold	text-white">
						&copy; {{year}} Mindfield Biosystems Ltd.
					</div>
					<div class="d-flex">
						<a href="https://www.mindfield.de/en/Lower-Menu/Privacy-Policy-of-Mindfield-Biosystems-Ltd.html"
							class="text-white">Data Privacy</a>
						<a href="https://www.mindfield.de/en/lower-menu/about-us.html" class="text-white ml-10">About
							Us</a>
						<a href="https://www.mindfield.de/en/Lower-Menu/Contact.html"
							class="text-white ml-10">Contact</a>
					</div>
				</div>
				<!--end: Aside footer for desktop-->
			</div>

		</div>
		<!--begin::Aside-->

		<!--begin::Content-->
		<div class="flex-row-fluid d-flex flex-column position-relative p-7 overflow-hidden">
			<!--begin::Content header-->
			<!--end::Content header-->

			<router-outlet></router-outlet>
			<!--begin::Content footer for mobile-->
			<div
				class="d-flex d-lg-none flex-column-auto flex-column flex-sm-row justify-content-between align-items-center mt-5 p-5">
				<div class="text-dark-50 font-weight-bold order-2 order-sm-1 my-2">
					&copy; 2020 Mindfield Biosystems Ltd.
				</div>
				<div class="d-flex order-1 order-sm-2 my-2">
					<a href="#" class="text-dark-75 text-hover-primary">Privacy</a>
					<a href="#" class="text-dark-75 text-hover-primary ml-4">Legal</a>
					<a href="#" class="text-dark-75 text-hover-primary ml-4">Contact</a>
				</div>
			</div>
			<!--end::Content footer for mobile-->
		</div>
		<!--end::Content-->
	</div>
</div>
