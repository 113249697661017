<ng-container *ngIf="user$ | async as _user">
	<div class="topbar-item" data-placement="bottom">
    <div class="btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2" id="kt_quick_user_toggle">
				<ng-container *ngIf="greeting">
          <span class="text-muted font-weight-bold font-size-base d-none d-md-inline mr-1">Hi,</span>
        </ng-container>
        <span class="text-dark-50 font-weight-bolder font-size-base d-none d-md-inline mr-3">
          {{_user.displayName}}
        </span>
				<span class="symbol symbol-35 symbol-light-success">
					<span class="symbol-label font-size-h5 font-weight-bold" style="background-color: #6c757d;
					color: #17224a;">
						{{_user.displayName | firstLetter}}
					</span>
				</span>
		</div>
	</div>
</ng-container>
