import { Injectable } from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ChoosenSensorService {

  choosenSensor: BehaviorSubject<any> = new BehaviorSubject<any>('');

  constructor() { }
}
