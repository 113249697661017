<div
	class="position-absolute top-0 text-right mt-5 mb-15 mb-lg-0 flex-column-auto justify-content-center py-5 px-10 register-top-nav">
	<span class="font-weight-bold text-dark-50">
		Back to Login
	</span>
	<a href="javascript:;" routerLink="/auth/login" class="font-weight-bold ml-2"
		id="kt_login_signup">{{ "Back" | translate }}</a>
</div>
<div class="d-flex flex-column-fluid flex-center mt-30 mt-lg-0" style="height: 700px; margin-top: 4.5rem !important">
	<!--begin::Signup-->
	<div class="login-form login-signup" style="display: block; height: 570px;">
		<div class="text-center mb-10 mb-lg-20">
			<h3 class="font-size-h1">Registration</h3>
		</div>

		<kt-auth-notice></kt-auth-notice>

		<form class="form" [formGroup]="registerForm" autocomplete="off">
			<div class="form-group" style="padding-bottom: unset">
				<mat-form-field>
					<mat-label>{{ 'AUTH.INPUT.EMAIL' | translate }}</mat-label>
					<input matInput type="email" placeholder="{{ 'AUTH.INPUT.EMAIL' | translate }}"
						formControlName="email" autocomplete="off" />
					<mat-error *ngIf="isControlHasError('email','required')">
						<strong>{{ 'AUTH.VALIDATION.REQUIRED_FIELD' | translate }}</strong>
					</mat-error>
					<mat-error *ngIf="isControlHasError('email','email')">
						<strong>{{ 'AUTH.VALIDATION.INVALID_FIELD' | translate }}</strong>
					</mat-error>
					<mat-error *ngIf="isControlHasError('email','minlength')">
						<strong>{{ 'AUTH.VALIDATION.MIN_LENGTH_FIELD' | translate }} 3</strong>
					</mat-error>
					<mat-error *ngIf="isControlHasError('email','maxlength')">
						<strong>{{ 'AUTH.VALIDATION.MAX_LENGTH_FIELD' | translate }} 320</strong>
					</mat-error>
				</mat-form-field>
			</div>
			<div class="form-group" style="padding-bottom: unset">
				<mat-form-field>
					<mat-label>{{ 'AUTH.INPUT.PASSWORD' | translate }}</mat-label>
					<input matInput type="password" placeholder="{{ 'AUTH.INPUT.PASSWORD' | translate }}"
						formControlName="password" autocomplete="off" [type]="hide ? 'password' : 'text'" />
					<button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'"
						[attr.aria-pressed]="hide">
						<mat-icon>{{ hide ? 'visibility_off' : 'visibility'}}</mat-icon>
					</button>
					<mat-error *ngIf="isControlHasError('password','required')">
						<strong>{{ 'AUTH.VALIDATION.REQUIRED_FIELD' | translate }}</strong>
					</mat-error>
					<mat-error *ngIf="isControlHasError('password','minlength')">
						<strong>{{ 'AUTH.VALIDATION.MIN_LENGTH_FIELD' | translate }} 3</strong>
					</mat-error>
					<mat-error *ngIf="isControlHasError('password','maxlength')">
						<strong>{{ 'AUTH.VALIDATION.MAX_LENGTH_FIELD' | translate }} 100</strong>
					</mat-error>
				</mat-form-field>
			</div>
			<div class="form-group" style="padding-bottom: unset">
				<mat-form-field>
					<mat-label>Name</mat-label>
					<input matInput type="text" placeholder="Name" formControlName="username" />
					<mat-error *ngIf="isControlHasError('username','required')">
						<strong>{{ 'AUTH.VALIDATION.REQUIRED_FIELD' | translate }}</strong>
					</mat-error>
					<mat-error *ngIf="isControlHasError('username','minlength')">
						<strong>{{ 'AUTH.VALIDATION.MIN_LENGTH_FIELD' | translate }} 3</strong>
					</mat-error>
					<mat-error *ngIf="isControlHasError('username','maxlength')">
						<strong>{{ 'AUTH.VALIDATION.MAX_LENGTH_FIELD' | translate }} 100</strong>
					</mat-error>
				</mat-form-field>
			</div>
			<div class="form-group" style="padding-bottom: unset">
				<mat-form-field>
					<mat-label>{{ 'Age' | translate }}</mat-label>
					<input matInput type="text" placeholder="{{ 'Age' | translate }}" formControlName="age"
						autocomplete="off" />
					<!-- <mat-error *ngIf="isControlHasError('confirmPassword','required')">
						<strong>{{ 'AUTH.VALIDATION.REQUIRED_FIELD' | translate }}</strong>
					</mat-error>
					<mat-error *ngIf="isControlHasError('confirmPassword','minlength')">
						<strong>{{ 'AUTH.VALIDATION.MIN_LENGTH_FIELD' | translate }} 3</strong>
					</mat-error>
					<mat-error *ngIf="isControlHasError('confirmPassword','maxlength')">
						<strong>{{ 'AUTH.VALIDATION.MAX_LENGTH_FIELD' | translate }} 100</strong>
					</mat-error>
					<mat-error
						*ngIf="registerForm.get('confirmPassword').errors && registerForm.get('confirmPassword').errors.ConfirmPassword">
						<strong>Passsword and ConfirmPassword didn't match.</strong>
					</mat-error> -->
				</mat-form-field>
				<!-- <mat-form-field> -->

					<!-- <input matInput type="text" placeholder="{{ 'Gender' | translate }}"
						formControlName="gender" autocomplete="off" /> -->
					<!-- <mat-error *ngIf="isControlHasError('confirmPassword','required')">
						<strong>{{ 'AUTH.VALIDATION.REQUIRED_FIELD' | translate }}</strong>
					</mat-error>
					<mat-error *ngIf="isControlHasError('confirmPassword','minlength')">
						<strong>{{ 'AUTH.VALIDATION.MIN_LENGTH_FIELD' | translate }} 3</strong>
					</mat-error>
					<mat-error *ngIf="isControlHasError('confirmPassword','maxlength')">
						<strong>{{ 'AUTH.VALIDATION.MAX_LENGTH_FIELD' | translate }} 100</strong>
					</mat-error>
					<mat-error
						*ngIf="registerForm.get('confirmPassword').errors && registerForm.get('confirmPassword').errors.ConfirmPassword">
						<strong>Passsword and ConfirmPassword didn't match.</strong>
					</mat-error> -->
				<!-- </mat-form-field> -->
			</div>
			<div class="form-group" style="padding-bottom: unset">
				<mat-form-field>
					<mat-label>{{ 'Gender' | translate }}</mat-label>
					<mat-select placeholder="Gender" formControlName="gender">
						<mat-option value="Female">Female</mat-option>
						<mat-option value="Male">Male</mat-option>
						<mat-option value="Other">Other</mat-option>
					</mat-select>
					<!-- <mat-error *ngIf="isControlHasError('confirmPassword','required')">
						<strong>{{ 'AUTH.VALIDATION.REQUIRED_FIELD' | translate }}</strong>
					</mat-error>
					<mat-error *ngIf="isControlHasError('confirmPassword','minlength')">
						<strong>{{ 'AUTH.VALIDATION.MIN_LENGTH_FIELD' | translate }} 3</strong>
					</mat-error>
					<mat-error *ngIf="isControlHasError('confirmPassword','maxlength')">
						<strong>{{ 'AUTH.VALIDATION.MAX_LENGTH_FIELD' | translate }} 100</strong>
					</mat-error>
					<mat-error
						*ngIf="registerForm.get('confirmPassword').errors && registerForm.get('confirmPassword').errors.ConfirmPassword">
						<strong>Passsword and ConfirmPassword didn't match.</strong>
					</mat-error> -->
				</mat-form-field>
			</div>
			<div class="form-group" style="padding-bottom: unset">
				<mat-checkbox name="agree" formControlName="agree">
					I agree the <a href="https://www.websitepolicies.com/policies/view/kaX1BwAE">terms & conditions</a>
				</mat-checkbox>
			</div>
      <div class="captcha form-group d-flex flex-wrap flex-center">
        <ngx-recaptcha2 siteKey="6Lf2nE4fAAAAALNH5JAo-jEoSJ0K1SEoVvfAGhPR" (success)="handleSuccess($event)"
                        formControlName="recaptcha">
        </ngx-recaptcha2>
      </div>
			<!--begin::Action-->
			<div class="form-group d-flex flex-wrap flex-center">
				<button [disabled]="registerForm.invalid" style="width: 100%;" (click)="submit()" id="kt_login_signup_submit"
					class="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
					[ngClass]="{'spinner spinner-right spinner-md spinner-light': loading}">{{ 'AUTH.GENERAL.SUBMIT_BUTTON' | translate }}</button>
				<a style="width: 100%;" routerLink="/auth/login" id="kt_login_signup_cancel"
					class="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4">Cancel</a>
			</div>
			<!--end::Action-->
		</form>
	</div>
	<!--end::Signup-->
</div>
