// Angular
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
// Partials
import { PartialsModule } from '../partials/partials.module';
// Pages
import { CoreModule } from '../../core/core.module';
import { ComponentsModule } from 'src/app/components/components.module';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { ArchiveModule } from './archive/archive.module';
import { ChartsModule } from '@rinminase/ng-charts';


// ngrx imports
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { archiveReducer } from 'src/app/core/archive/_reducers/archive.reducer';
import { ArchiveEffects } from 'src/app/core/archive/_effects/archieve.effects';
import { NgxPermissionsModule } from 'ngx-permissions';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [],
  exports: [],
  imports: [
    CommonModule,
    HttpClientModule,
    FormsModule,
    CoreModule,
    PartialsModule,
    ComponentsModule,
    AngularFirestoreModule,
    ChartsModule,
    StoreModule.forFeature('archive', archiveReducer),
    EffectsModule.forFeature([ArchiveEffects]),
    NgxPermissionsModule.forChild(),
    TranslateModule.forChild()
  ],
  providers: []
})
export class PagesModule {
}
